import React from 'react'
import PropTypes from 'prop-types'
import Content from './Content'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLanguage } from '@fortawesome/free-solid-svg-icons'


const BasicL10nPageTemplate = ({ title, notice, content, contentComponent, asideImages, lang, url }) => {
  const PageContent = contentComponent || Content


  function Notice({notice}) {
    if(notice.title !== null) {
      return (
        <article className="message">
          <div className="message-header">
            {notice.title}
          </div>
          <div className="message-body">
            <p>{notice.message}</p>
          </div>
        </article>
      )
    }
    else {
      return (
        < ></ >
      )
    }
  }

  const getLabel = langKey => {
  switch (langKey) {
      case 'en': return 'English';
      case 'es': return 'Español';
      case 'vi': return 'Tiếng Việt';
      case 'zh': return '中文';
      default: return null;
    }
  };
  function AsideImages({asideImages}) {
    if( !Array.isArray(asideImages) || !asideImages.length) {
      return (
        < ></ >
      )
    }

    return (
      <div className="column is-3 is-offset-1 vertical-justify">
        {asideImages.map(image => (
          < >
            <Image className="asideImage" fixed={image.image.childImageSharp.fixed} alt={image.image.alt} />
          </ >
        ))}
      </div>
    )
  }
  const langLinks = lang.map( langS =>
      <Link to={`${langS.link}`} className={`dropdown-item ${langS.selected ? 'is-active':''}`}>{getLabel(langS.langKey)}</Link>
    )
  return (
    <section className="section section--gradient">
      <div className="content-spacer"></div>
      <section className="section">
      <div className="container">
        <div className="columns has-l10n-text">
          <div className="column is-3 has-text-right">
            <div class="dropdown is-right is-hoverable">
              <div class="dropdown-trigger">
                <button class="button is-white" aria-haspopup="true" aria-controls="dropdown-menu">
                  <span>Choose Language</span>
                  <span class="icon is-huge">
                      <FontAwesomeIcon size="2x" icon={faLanguage} />
                    </span>
                </button>
              </div>
              <div class="dropdown-menu has-text-centered" id="dropdown-menu" role="menu">

                <div class="dropdown-content">
                  {langLinks}
                </div>
              </div>
            </div>
          </div>
          <div className="column is-9">
            <h2 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h2>
          </div>
        </div>
        <div className="columns">
          <div className="column is-8 is-offset-3">
            <Notice notice={notice} />
            <PageContent className="content" content={content} />
          </div>
          <AsideImages asideImages={asideImages} />
        </div>
      </div>
      </section>
    </section>
  )
}

BasicL10nPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default BasicL10nPageTemplate
